//SidebarShowAction action types...
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';

//ActionListActions action types...
export const GET_ALL_ACTION_LIST = 'GET_ALL_ACTION_LIST';


//AdminUserManagementActions action types...
export const GET_ADMINS = 'GET_ADMINS';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL';
export const GET_SUB_ADMINS = 'GET_SUB_ADMINS';


//LoginActions action types...
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

//OrganizerManagementActions action types...
export const GET_ACTION_LIST = 'GET_ACTION_LIST';
export const GET_NEED_LIST = 'GET_NEED_LIST';
export const GET_ORGANIZAIONS = 'GET_ORGANIZAIONS';

// SalesManagementActions ation types...
export const GET_ORDERS = 'GET_ORDERS';

//VolunteersManagementActions action types...
export const GET_VOLUNTEERS = 'GET_VOLUNTEERS';

//SubscriptionManagementActions action types...
export const GET_SUBSCRIPTIONS_PLANS_SUCCESS = 'GET_SUBSCRIPTIONS_PLANS_SUCCESS';
export const GET_SUBSCRIPTIONS_PLANS_FAIL = 'GET_SUBSCRIPTIONS_PLANS_FAIL';

// CommissionManagementActions action types...
export const GET_COMMISSION_PERCENTAGE = 'GET_COMMISSION_PERCENTAGES';


// DonationsManagementActions ation types...
export const GET_DONATIONS = 'GET_DONATIONS';

// WithdrawalManagementActions ation types...
export const GET_WITHDRAWAL_REQUESTS_SUCCESS = 'GET_WITHDRAWAL_REQUESTS_SUCCESS';
export const GET_WITHDRAWAL_REQUESTS_FAIL = 'GET_WITHDRAWAL_REQUESTS_FAIL'

