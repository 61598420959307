import {GET_ORDERS} from '../actionType';

const initialState = {
  ordersData: null
}

export const salesManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS :
      return {...state, 
        ordersData: action.data 
    }
    default:
      return state
  }
}