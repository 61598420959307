import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import httpService from "../../services/httpService";
import CIcon from '@coreui/icons-react'

function PasswordSet({ match }) {
  const [password, setPassword] = useState("");
  let [add, setAdd] = useState(true);
  let [errors, setErrors] = useState({});
  let [val1, setVal1] = useState(false);

  function validateAdmin(e, type) {
    switch (type) {
      case "password":
        setPassword(e.target.value);
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}$/.test(
            e.target.value
          ) === false
        ) {
          setErrors({
            password:
              "12 characters, min one uppercase, lowercase letter and special character required",
          });
          setVal1(false);
        } else {
          setErrors({ password: "" });
          setVal1(true);
        }
        break;
      default:
        return 0;
    }
  }

  const setPasswordAPI = () => {
    let data = {
      setPasswordToken: match.params.id,
      password: password,
    };
    httpService
      .post("/admin/setPasswordForAdmin", data)
      .then((res) => {
        toast.success(res.data.msg);
        setPassword("");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (val1) {
      setAdd(false);
    } else {
      setAdd(true);
    }
  }, [password]);

  return (
    <>
      <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="5">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h2>ActionList Set Password</h2><br/>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" value={password} placeholder="Your Password" autoComplete="current-password" onChange={(e) => { validateAdmin(e, "password")}}/>
                    </CInputGroup>
                    {errors?.password && (
                      <p className="alert alert-danger mb-2">{errors.password}</p>
                    )}
                    <CRow>
                      <CCol xs="6">
                        <CButton color="primary" className="px-4" onClick={() => setPasswordAPI()} disabled={!val1}  >Set</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      <ToastContainer autoClose={3000} />
    </div>

    </>
  );
}

export default PasswordSet;