import {GET_ADMINS, ADD_ADMIN_SUCCESS, ADD_ADMIN_FAIL, GET_SUB_ADMINS} from '../actionType';
import httpService from "../../services/httpService";

export const getSub_AdminsList = (token) => {
  return async (dispatch) => {
    const response = await httpService.get(
      "/admin/adminList?adminType=sub_admin",
      { headers: { token: token } }
    );
    if(response){
      const { data } = response;
      dispatch({type: GET_SUB_ADMINS, sub_admins: data?.adminData})  
    }
  }
}

export const getAdminsList = (token) => {
  return async (dispatch) => {
    const response = await httpService.get(
      "/admin/adminList?adminType=admin",
      { headers: { token: token } }
    );
    if(response){
      const { data } = response;
      dispatch({type: GET_ADMINS, admins: data?.adminData})
      return response
    }
  }
}

export const addAdmin = (token,adminId,data)=> {
  return (dispatch) => {
    httpService
        .post("/admin/addAdmin", data, {
          headers: {
            token: token,
            superAdminId: adminId,
          },
        })
        .then((res) => {
          dispatch({type: ADD_ADMIN_SUCCESS, payload: res})
        })
        .catch((err) => {
          dispatch({type: ADD_ADMIN_FAIL, err})
        });
  }
}


export const updateAdmin = (token, id, data) => async (dispatch) => {
  try{
      const response = await httpService.post("/admin/updateAdmiDetails", data, {
        headers: {
          token: token,
          superAdminId: id
        },
      })
      return response
  } 
  catch (error){
      return error
  }
}

export const getSubAdminRoles = (token) => async (dispatch) => {
  try{
    const response = await httpService.get("/admin/getRolesData", { headers: { token: token } })
    return response
  }catch (error){
    return error
  }
}

export const setSubAdminPermissions = (data, token, id) => async (dispatch) => {
  try{
    const response = await httpService.post("/admin/setAdminPermission", data, {headers:{token: token, superAdminId: id }})
    return response
  }catch (error){
    return error
  }
}