import {GET_ORDERS} from '../actionType';
import httpService from "../../services/httpService";

export const getOrders = (token) => {
  return async (dispatch)=> {
    let response = await httpService.get("order/getAllOrders", {
        headers: { token: token },
      });
    if(response){
      let {data} = response;
      dispatch({type: GET_ORDERS, data})
    }
  }
}