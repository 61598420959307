import { GET_WITHDRAWAL_REQUESTS_FAIL, GET_WITHDRAWAL_REQUESTS_SUCCESS } from "../actionType";


const initialState = {
    withdrawalData: [],
    error: null
  }
  
  export const withdrawalManagementReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_WITHDRAWAL_REQUESTS_SUCCESS :
        return {...state,
            withdrawalData: action?.payload, 
            error: null
        }
        case GET_WITHDRAWAL_REQUESTS_FAIL :
            return {...state,
                withdrawalData: [], 
                error: action?.error
            }  
      default:
        return state
    }
  }