import {GET_COMMISSION_PERCENTAGE} from '../actionType';
import httpService from "../../services/httpService";

export const getCommissionPercentage = (token) => {
  return async (dispatch) => {
    const response = await httpService.get(
      "/admin/commissionPercentageRate",
      { headers: { token: token } }
    );
    
    if (response) {
      const { data } = response;
      dispatch({ type: GET_COMMISSION_PERCENTAGE, payload: data });
      return data;
    }
  }
}

export const addCommissionPercentageRate = (token, data) => async (dispatch) => {
  try{
    const response = await httpService.post(
      "/admin/commissionPercentageRate", data,
      { headers: { token: token } }
    );
    return response
  }
  catch (error){
    return error
  }
}

export const updateCommissionPercentageRate = (token, data) => async (dispatch) => {
  try{
    const response = await httpService.post(
      "/admin/updateCommissionPercentage", data,
      { headers: { token: token } }
    );
    return response
  }
  catch (error){
    return error
  }
}

