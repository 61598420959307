import {GET_VOLUNTEERS} from '../actionType';

const initialState = {
  volunteersData: null
}

export const volunteersManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VOLUNTEERS :
      return {...state,
        volunteersData: action.data 
      }
    default:
      return state
  }
}