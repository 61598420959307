import {GET_COMMISSION_PERCENTAGE} from '../actionType';

const initialState = {
  commissionPersentageData: ''
}

export const commissionManagementReducer = (state= initialState, action) => {
  switch (action.type){
    case GET_COMMISSION_PERCENTAGE:
      return{
        ...state,
        commissionPersentageData: action.payload
      }
    default:
      return state;  
  }
}