import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as action from '../../../redux';
import httpService from "../../../services/httpService";


import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [apiSuccessRes, setApiSuccessRes] = useState('')
  
  
  const handleSubmit = async () => {
    const eRglEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(eRglEx.test(String(email).toLowerCase())){
      const data = {
        email: email
      }
      try{
        const res = await httpService.post('/admin/adminForgotPassword',data)
        setApiSuccessRes(res?.data?.msg)
      }catch(error){
        console.log("errorororo",error)
        setErrorMsg("Admin not exist!")
        setApiSuccessRes('')
      }
    }else{
      setErrorMsg("Enter a valid email address")
    }
    
  }
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="4">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  {!apiSuccessRes && <CForm>
                    <h1>Forgot Password</h1>
                    {errorMsg && <p className="alert alert-danger">{errorMsg}</p>}
                    <p className="text-muted">Enter your email address </p>
                    {/* {error && <p className="alert alert-danger">{error?.msg}</p>} */}
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" value={email} placeholder="Email" autoComplete="username" onChange={e => {setEmail(e?.target?.value); setErrorMsg('')}} />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton style={{cursor:"pointer"}} color="primary" className="px-8" disabled={!email} onClick={handleSubmit}>Submit</CButton>
                      </CCol>
                    </CRow>
                    <div class='forgot-link pt-3'>
                      <a href='#/login'>
                        Back to Log in
                      </a>
                    </div>
                  </CForm>}
                  {apiSuccessRes && 
                    <div class='row'>
                    <div class='col-12'>
                      <h2 class='heading2 signup-head pt-2 pt-xl-5 pt-lg-5 pt-md-5'>Instructions sent</h2>
                        <p class='para'>
                          Instructions for reseting your password have been sent to{' '}
                          <strong>{email}</strong>
                        </p>
                        <p class='para pt-3'>
                          You’ll receive this email within 5 minutes. Be sure to check your spam
                          folder.
                        </p>
                        <div class='forgot-link pt-3'>
                          <a href='#/login'>
                            Back to Log in
                          </a>
                        </div>
                    </div>
                  </div>
                  }

                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgotPassword;