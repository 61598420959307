import {GET_ACTION_LIST, GET_NEED_LIST, GET_ORGANIZAIONS} from '../actionType';

const initialState = {
  actionListData: null,
  needListData: null,
  organizersData: null
}

export const organizerManagementReducer = (state = initialState, action) => {
  switch (action.type){
  case GET_ACTION_LIST:
    return{
      ...state,
      actionListData: action.data
    }
  case GET_NEED_LIST:
    return{
      ...state,
      needListData: action.data
    }
  case GET_ORGANIZAIONS:
    return{
      ...state,
      organizersData: action.data
    }
  default:
    return state
  }
}