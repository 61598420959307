import {GET_VOLUNTEERS} from '../actionType';
import httpService from "../../services/httpService";

export const getVolunteersList = (token) => {
  return async (dispatch)=> {
      await httpService.get("/user/usersList?userType=volunteer", {
      headers: { token: token },
    })
    .then((response) => {
      let { data } = response;
      dispatch({type: GET_VOLUNTEERS, data})
    });
  }
}
