import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as action from '../../../redux';
import httpService from "../../../services/httpService";
import { useHistory } from 'react-router';


import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ToastContainer, toast } from 'react-toastify';

const PasswordReset = ({ match }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [apiSuccessRes, setApiSuccessRes] = useState('')
  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)
  const history = useHistory();

  useEffect(()=>{
    setTimeout(()=>{
      apiSuccessRes && history?.push('/login')
    },2000)
  },[apiSuccessRes])

  const handleSubmit = async () => {
    const passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}$/;

    if ((passwordRegEx.test(password) && (password === confirmPassword))) {
      let data = {
        password: password
      };
      try {
        const res = await httpService.post('admin/adminResetPassword?token='+ match?.params?.id, data)
        toast.success(res?.data?.msg)
        setApiSuccessRes(res?.data?.msg)
      } catch (error) {
        setErrorMsg(error?.msg)
        toast.error(error?.msg)
      }
    } else {
      if(password !== confirmPassword){
        setErrorMsg("Password did not match: Please enter valid password");
      }else if(!passwordRegEx.test(String(password).toLowerCase())){
        setErrorMsg("12 characters, min one uppercase, lowercase letter and special character required");
      } 
    }
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="4">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>ActionList Reset Password</h1>
                    {errorMsg && <p className="alert alert-danger">{errorMsg}</p>}
                    <p className="text-muted">Password</p>
                    {/* {error && <p className="alert alert-danger">{error?.msg}</p>} */}
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name={passwordShow ? "cis-lock-unlocked" : "cil-lock-locked"} onClick={()=> setPasswordShow(!passwordShow)}/>
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type={passwordShow ? "text" : "password"} value={password} placeholder="Password" onChange={e => { setPassword(e?.target?.value); setErrorMsg('') }} />
                    </CInputGroup>
                    <p className="text-muted">Confirm Password</p>
                    {/* {error && <p className="alert alert-danger">{error?.msg}</p>} */}
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                        <CIcon name={confirmPasswordShow ? "cil-lock-unlocked" : "cil-lock-locked"} onClick={()=> setConfirmPasswordShow(!confirmPasswordShow)}/>
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type={confirmPasswordShow ? "text" : "password"} value={confirmPassword} placeholder="Password" onChange={e => { setConfirmPassword(e?.target?.value); setErrorMsg('') }} />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton style={{ cursor: "pointer" }} color="primary" className="px-8" onClick={handleSubmit}>Submit</CButton>
                      </CCol>
                    </CRow>
                    <div class='forgot-link pt-3'>
                      <a href='#/login'>
                        Back to Log in
                      </a>
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <ToastContainer autoClose={2000} />
      </CContainer>
    </div>
  )
}

export default PasswordReset;