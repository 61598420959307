import {GET_ACTION_LIST, GET_NEED_LIST, GET_ORGANIZAIONS} from '../actionType';
import httpService from "../../services/httpService";

export const getActionListByUserId = (token, id) => {
  return async (dispatch) => {
    let response = await httpService.get(
      "actionList/getActionListByUserId/"+id,
      { headers: { token: token } }
    );
    if(response){
      let { data } = response;
      dispatch({type: GET_ACTION_LIST, data})
    }
  }
}

export const getNeedsListByUserId = (token, id) => {
  return async (dispatch) => {
    let response = await httpService.get("actionList/getNeedsByActionListId/"+id,{
      headers: { token: token },
    });
    if(response){
      let { data } = response;
      dispatch({type: GET_NEED_LIST, data})
    }
  }
}

export const getOrganizationsData = (token) => {
  return async(dispatch) => {
    let response = await httpService.get(
      "/user/usersList?userType=organization",
      { headers: { token: token } }
    );
    if(response){
      let { data } = response;
      dispatch({type: GET_ORGANIZAIONS, data})
    }
  }
}

export const userBlock = (token, id) => {
  return async(dispatch) => {
    let response = await httpService.post(
      "/user/blockUser?userId=" + id,
      {},
      { headers: { token: token } }
    );
    dispatch(getOrganizationsData(token));
  }
}

export const userUnblock = (token, id) => {
  return async(dispatch) => {
    let response = await httpService.post(
      "/user/unblockUser?userId=" + id,
      {},
      { headers: { token: token } }
    );
    dispatch(getOrganizationsData(token));
  }
}

export const deleteOrganizer = (token, id) => async (dispatch) => {
  try{
    const response = await httpService.delete(
      "/admin/removeOrganizer/" + id,
      { headers: { token: token } }
    );
    dispatch(getOrganizationsData(token));
    return response
  }
  catch (error){
    return error
  }
}

export const createActionListVerifiedStatus = (userId, token, actionListStatus) => async (dispatch) => {
  try{
    const response = await httpService.post("user/changeAllowCreateActionLIstStatus/"+userId+"?status="+actionListStatus, {headers:{token: token}})
    return response
  }catch(error){
    return error
  }
}