import {LOGIN_SUCCESS, LOGIN_FAIL} from '../actionType';

const initialState = {
  loginRes: null,
  error: null
}

export const loginReducer = (state = initialState, action)=> {
  switch(action.type){
    case LOGIN_SUCCESS:
      return{
          ...state,
          loginRes: action.data,
          error:null
      }
    case LOGIN_FAIL:
      return{
        ...state,
        error: action.err,
        loginRes: null
      }
    default:
      return state    
  }
}