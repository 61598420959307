import { GET_DONATIONS } from '../actionType';

const initialState = {
  donationsData: []
}

export const donationsManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DONATIONS:
      return {
        ...state,
        donationsData: action.payload
      }
    default:
      return state
  }
}