import {SHOW_SIDEBAR} from '../actionType';

const initialState = {
    sidebarShow: 'responsive'
  }

export const sidebarShowReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SIDEBAR :
          return {...state, 
            sidebarShow: action.value 
        }
        default:
          return state
      }
}