import {GET_ADMINS, ADD_ADMIN_SUCCESS, ADD_ADMIN_FAIL, GET_SUB_ADMINS} from '../actionType';

const initialState = {
  adminsList: [],
  error: null,
  adminDetails: null,
  sub_adminsList: []
}

export const adminUserManagementReducer = (state=initialState, action) => {
  switch(action.type){
    case GET_ADMINS:
      return{
        ...state,
        adminsList: action.admins,
        error: null
      }
    case ADD_ADMIN_SUCCESS:
      return{
        ...state,
        adminDetails: action.payload,
        error: null
      }
    case ADD_ADMIN_FAIL:
      return{
        ...state,
        error: action.err
      }
    case GET_SUB_ADMINS:
      return{
        ...state,
        sub_adminsList: action.sub_admins
      }
    default:
      return state  
  }
}