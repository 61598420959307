import {GET_SUBSCRIPTIONS_PLANS_SUCCESS, GET_SUBSCRIPTIONS_PLANS_FAIL} from '../actionType';

const initialState = {
  subscriptionPlansData: null,
  error: null
}

export const subscriptionManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_PLANS_SUCCESS :
      return {...state,
        subscriptionPlansData: action.payload,
        error: null
      }
    case GET_SUBSCRIPTIONS_PLANS_FAIL :
      return {...state,
        error: action.error 
      }
    default:
      return state
  }
}