import {GET_ALL_ACTION_LIST}  from '../actionType';
import httpService from "../../services/httpService";

export const getAllActionListData = (token) => {
  return async (dispatch) => {
    let response = await httpService.get("actionList/getAllActionLists", {
      headers: { token: token },
    });
    if(response){
      let { data } = response;
      dispatch({type: GET_ALL_ACTION_LIST, data})
    }
  }
}

export const changeActionListVerifiedStatus = (actionListId, token, actionListStatus) => async (dispatch)=> {
  try{
    const response = await httpService.post("actionList/changeActionListVerifiedStatus/"+actionListId+"?status="+actionListStatus, {headers:{token: token}})
    dispatch(getAllActionListData(token))
    return response
  }catch(error){
    return error
  }
}