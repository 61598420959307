import { combineReducers } from "redux";

import { actionListReducer } from "./ActionListReducer";
import { sidebarShowReducer } from "./SidebarShowReducer";
import { adminUserManagementReducer } from "./AdminUserManagementReducer";
import { loginReducer } from "./LoginReducer";
import { organizerManagementReducer } from "./OrganizerManagementReducer";
import { salesManagementReducer } from "./SalesManagementReducer";
import { volunteersManagementReducer } from "./VolunteersManagementReducer";
import {subscriptionManagementReducer} from './SubscriptionManagementReducer'
import {commissionManagementReducer} from './CommissionManagementReducer'
import {donationsManagementReducer} from './DonationsManagementReducer'
import { withdrawalManagementReducer } from "./WithdrawalManagementReducer";

export const rootReducer = combineReducers({
  adminUserManagement: adminUserManagementReducer,
  actionList: actionListReducer,
  sidebar: sidebarShowReducer,
  login: loginReducer,
  organizerManagement: organizerManagementReducer,
  salesManagement: salesManagementReducer,
  volunteersManagement: volunteersManagementReducer,
  subscriptionManagement: subscriptionManagementReducer,
  commissionManagement: commissionManagementReducer,
  donationsManagement: donationsManagementReducer,
  withdrawalManagement: withdrawalManagementReducer
});
