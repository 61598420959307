import {GET_ALL_ACTION_LIST } from '../actionType';

const initialState = {
  allActionListData: null
}

export const actionListReducer = (state = initialState, action) => {
  switch (action.type){
    case GET_ALL_ACTION_LIST:
      return{
        ...state,
        allActionListData: action.data
      }
    default:
      return state
  }
}