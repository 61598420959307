import {LOGIN_SUCCESS, LOGIN_FAIL} from '../actionType';
import httpService from "../../services/httpService";
import Cookies from "js-cookie";

export const adminLogin = (email, password) => {
    return (dispatch)=>{
        httpService.post('/admin/login',{
            email:email,
            password:password
          }).then(res=>{
            dispatch({type: LOGIN_SUCCESS, data: res?.data})
            Cookies.set("adminData", JSON.stringify(res.data))
            Cookies.set("token", JSON.stringify(res.data.token))
            console.log("login",res.data)
            window.location.reload()
          })
          .catch((err)=>{
            console.log(err.response.data)
            dispatch({type: LOGIN_FAIL, err})
          })
    }
}