import { GET_SUBSCRIPTIONS_PLANS_SUCCESS, GET_SUBSCRIPTIONS_PLANS_FAIL } from '../actionType';
import httpService from '../../services/httpService';

export const getSubscriptionPlans = (token) => async (dispatch) => {
  try {
    const response = await httpService.get('/admin/getSubscriptions', {
      headers: { token: token },
    })
    dispatch({ type: GET_SUBSCRIPTIONS_PLANS_SUCCESS, payload: response?.data?.subscriptions });
    return response
  } catch (error) {
    dispatch({ type: GET_SUBSCRIPTIONS_PLANS_FAIL, error });
    return error
  }
}

export const updateSubscription = (token, data) => async (dispatch) => {
  try {
    const response = await httpService.post('/admin/createSubsription', data, {
      headers: {
        token: token
      },
    })
    return response
  } catch (error) {
    return error
  }
}

export const addSubcriptionPlan = (token, data) => async (dispatch) => {
  try {
    const response = await httpService.post(
      "/admin/subsription", data,
      { headers: { token: token } }
    );
    return response
  }
  catch (error) {
    return error
  }
}

export const updateSubcriptionPlan = (token, data) => async (dispatch) => {
  try {
    const response = await httpService.post(
      "/admin/subsription", data,
      { headers: { token: token } }
    );
    return response
  }
  catch (error) {
    return error
  }
}