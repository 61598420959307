import React, { Component } from 'react';
import {HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
import Cookies from 'js-cookie'
import './scss/style.scss';
import PasswordSet from './views/pages/passwordSet';
import ForgotPassword from './views/pages/login/Forgot-Password';
import PasswordReset from './views/pages/login/Password-Reset';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
// const SetPassword = React.lazy(()=> import('./views/pages/passwordSet'))
// 
class App extends Component {

  render() {
    console.log("test frontend ci cd -admin");
    return (
      <HashRouter >
          <React.Suspense fallback={loading}>
            <Switch>
              {!Cookies.get('token') && <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />}
              <Route exact path="/forgot-password" name="Forgot-Password" render={props => <ForgotPassword {...props}/>} />
              {/*<Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />*/}
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/set-password/:id" name="Set Password" render={props => <PasswordSet {...props}/>} />
              <Route exact path="/reset-password/:id" name="Reset Password" render={props => <PasswordReset {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
